import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import Layout from '../../components/layout/layout';

import styles from './category.module.scss';
import ArticleCard from '../../components/article-card/article-card';

interface Props {
  data: any;
}

const CategoryTemplate = ({data}: Props) => {
  const category = get(data, 'contentfulCategory');
  const articles = get(data, 'contentfulCategory.article');
  const siteTitle = get(data, 'site.siteMetadata.title');
  return (
    <Layout>
      <div>
        <Helmet title={`${category.title} | ${siteTitle}`} />
        <h2>{category.title}</h2>
        <div>{category?.description}</div>
        { articles ? articles.map((article: any) => {
            return(
              <ArticleCard article={article} />
            );
          }) : 'No articles found for category.'}
      </div>
    </Layout>
  )
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query CategoryByName($title: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCategory(title: { eq: $title }) {
      title
      description
      article {
        title
        summary
        slug
        heroImage {
          title
          fluid(maxWidth: 1180, background: "rgb:000000", quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
